export function useDecal () {
  const telegramUrl = 'https://t.me/parking04'
  const kakaoTalkUrl = ''
  const foreverDomainUrl = `http://${import.meta.env.VITE_FOREVER_DOMAIN}`

  return {
    telegramUrl,
    kakaoTalkUrl,
    foreverDomainUrl,
  }
}
